const ReloadState = require('./reloadState');

const Utils = window.relevantDigital.import('Utils');

const DEFAULT_UPDATE_MS = 1000; // How often we should check viewability and possibly reload placements
const DEFAULT_MAX_NO_ACTIVITY_MS = 300 * 1000; // stop reloading after 5 minutes of no scrolling

/**
 * "Singleton-like" object to handle all ReloadState objects. One ReloadState is created for every placement
 * that should be reloaded and is deleted after the final reload for the placement has been triggered. Reloads
 * are triggered by calling PrebidRequester.loadPrebid() again but only for the placements that should be reloaded.
 */
class Reloader {
	constructor({ pbRequester }) {
		Utils.assign(this, {
			pbRequester,
			stateById: {}, // ReloadState by ad-div id
			active: false,
			updateMs: DEFAULT_UPDATE_MS,
			maxNoActivityMs: DEFAULT_MAX_NO_ACTIVITY_MS,
			listenersInitialized: false,
		});
		this.pbRequester = pbRequester;
		pbRequester.addAuctionDoneListener((a) => this.onAuctionDone(a));
	}

	onAuctionDone(auction) {
		const { isReloadAuction } = auction;
		auction.usedUnitDatas.forEach(({ adUnit, slot }) => {
			const settings = adUnit.data.rlvReloadSettings || {};
			if (!settings.enabled || !settings.timeBetween || !settings.reloadTimes) {
				return; // Reloading disabled for placement
			}
			const elmId = slot.getSlotElementId();
			let state = this.stateById[elmId];
			if (state) {
				// If the ad-div has been replaced (maybe by navigating on a SPA-page) or if a new auction is triggered
				// for the placement - reset the state and thereby the reload-counter
				if (!state.isStillValid() || !isReloadAuction) {
					state = null; // reset state
				}
			}
			if (!state && !isReloadAuction) {
				state = new ReloadState({
					...settings,
					reloader: this,
					auction,
					adUnit,
					slot,
				});
				this.stateById[elmId] = state;
			}
		});
		if (Object.keys(this.stateById).length) {
			if (!this.listenersInitialized) {
				this.lastActivityTs = new Date();
				this.listenersInitialized = true;
				window.addEventListener('scroll', () => {
					this.lastActivityTs = new Date();
				});
			}
			this.scheduleUpdate(); // Starts update-loop, if needed
		}
	}

	scheduleUpdate() {
		if (!this.updateScheduled) {
			this.updateScheduled = true;
			setTimeout(() => this.update());
		}
	}

	update() {
		// Group ReloadState objects that should now be reloaded by auction to handle the possibility of auctions using
		// different settings. So placements for different calls to relevantDigital.laodPrebid() will not be reloaded
		// in the same reload auction.
		const toReload = new Map();

		const updateTs = new Date();

		// Check if tab is "hidden" and if so, stop counting viewable time and doing reloads. We also count tab
		// as "hidden" if there has been no scroll activity recently (user gone away..)
		const hidden = !document?.hasFocus() || updateTs - this.lastActivityTs > this.maxNoActivityMs;

		for (const elmId in this.stateById) {
			const state = this.stateById[elmId];
			if (hidden) {
				state.setHidden(); // Stop count viewability-time
			} else if (!state.isStillValid()) {
				delete this.stateById[elmId]; // Ad-div has changed / been replaced => stop reloading
			} else {
				state.update(updateTs);
				if (state.isReloadReady()) { // Placement should be reloaded..
					const arr = toReload.get(state.auction) || [];
					arr.push(state);
					toReload.set(state.auction, arr);
					if (!state.scheduleNextReload()) { // This is the last reload, delete object..
						delete this.stateById[elmId];
					}
				}
			}
		}
		if (toReload.size) {
			// Use setTimeout to prevent possibility of this.onAuctionDone() being called immediately
			setTimeout(() => {
				toReload.forEach((arr, auction) => {
					const allowedStates = arr.filter((state) => state.isReloadAllowed());
					if (!allowedStates.length) {
						return;
					}
					const settings = {
						...auction.settings, // copy previous auction-settings
						isReloadAuction: true,
						manageAdserver: false,
						noSlotReload: false,
						allowedDivIds: allowedStates.map(({ slot }) => slot.getSlotElementId()),
						mainAuction: auction.settings.mainAuction || auction,
					};
					[...auction.adservers].reverse().forEach((ads) => {
						if (!ads.isInstreamOnly()) {
							ads.finalizeReloadSettings(settings);
						}
					});
					this.pbRequester.loadPrebid(settings); // Trigger next auction
				});
			});
		}
		// Schedule next check, but only if there are still active ReloadState objects
		this.updateScheduled = !!Object.keys(this.stateById).length;
		if (this.updateScheduled) {
			setTimeout(() => this.update(), this.updateMs);
		}
	}
}

window.relevantDigital.export({ Reloader });
